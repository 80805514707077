import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MinerPlayer } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class WalletsService {

  treasury: BehaviorSubject<MinerPlayer> = new BehaviorSubject({
    name: "Treasury",
    address: "0xx00004",
    tokens: [ {
      symbol: "TLR",
      balance: 0
    }],
    state: "idle"
  } as MinerPlayer)

  developer: BehaviorSubject<MinerPlayer> = new BehaviorSubject({
    name: "Developer Wallet",
    address: "0xx00008",
    tokens: [ {
      symbol: "TLR",
      balance: 0
    }],
    state: "idle"
  } as MinerPlayer)

  players: BehaviorSubject<MinerPlayer[]> = new BehaviorSubject([] as MinerPlayer[])

  treasury$ = this.treasury.asObservable()
  developer$ = this.developer.asObservable()
  players$ = this.players.asObservable()

  constructor() { 
    this.initRandomPlayers(10)
  }

  initRandomPlayers(number: number) {
    var players: MinerPlayer[] = []

    for (let i = 1; i < number + 1; i++) {
      var player = {
        name: "P" + 1,
        address: "0xx0000" + i,
        tokens: [ {
          symbol: "TLR",
          balance: Math.random() * 1000
        }],
        state: "idle"
      }

      players.push(player as MinerPlayer)

      this.players.next(players)
    }
  }

}

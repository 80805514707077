import { Component, Input, OnInit } from '@angular/core';
import { MinerPlayer } from 'src/app/models/user';
import { LiquifyContractService } from 'src/app/services/liquify-contract.service';
import { StartMininigContractService } from 'src/app/services/start-mininig-contract.service';

@Component({
  selector: 'app-wallet-ui',
  templateUrl: './wallet-ui.component.html',
  styleUrls: ['./wallet-ui.component.scss']
})
export class WalletUIComponent implements OnInit {

  @Input() player!: MinerPlayer
  @Input() icon!: string

  constructor(
    public startMiningContract: StartMininigContractService,
    public liquifyContract: LiquifyContractService
  ) { }

  ngOnInit(): void {
  }

  startMining() {
    this.startMiningContract.startMining(this.player)
  }

}

import { Injectable } from '@angular/core';
import { MinerPlayer } from '../models/user';
import { WalletsService } from './wallets.service';

@Injectable({
  providedIn: 'root'
})
export class StartMininigContractService {

  constructor(private wallets: WalletsService) { }

  startMining(player: MinerPlayer) {

    var tlr = player.tokens.find(t => t.symbol === "TLR")

    if (!tlr || tlr.balance < 1) {
      alert("buy more TLR");
      return
    }

    tlr.balance -= 1;

    player.state = "mining";

    // add dev tlr
    var dev = this.wallets.developer.getValue()
    var dev_tlr = dev.tokens.find(t => t.symbol === "TLR")
    if (dev_tlr) {
      dev_tlr.balance += 0.1;
    } else {
      throw("no dev tlr")
    }

    // add treasury tlr
    var trs = this.wallets.treasury.getValue()
    var trs_tlr = trs.tokens.find(t => t.symbol === "TLR")
    if (trs_tlr) {
      trs_tlr.balance += 0.9;
    } else {
      throw("no trs tlr")
    }

  }

}

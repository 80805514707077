<div id="wallet">
    <div class="row">
        <mat-icon>{{ icon }}</mat-icon>
        <span>{{player.name}}</span>
    </div>
    <div class="row">
        <div class="token" *ngFor="let t of player.tokens">
            {{ t.balance | number:'1.2-2' }} {{ t.symbol }}
        </div> 
    </div>
    <ng-template 
            [ngIf]="player.state === 'idle'"
            [ngIfElse]="isMining">
        <button 
            (click)="startMining()"
            mat-flat-button>MINE
        </button>
    </ng-template>
    
    <ng-template #isMining>
        <mat-spinner [diameter]="20"></mat-spinner>
    </ng-template>
</div>
<h1>Wallets</h1>

<div class="house row">
    <div class="player" *ngIf="wallets.treasury$ | async as t">
        <app-wallet-ui icon="account_balance" [player]="t"></app-wallet-ui>
    </div>
    <div class="player" *ngIf="wallets.developer$ | async as dv">
        <app-wallet-ui icon="account_balance" [player]="dv"></app-wallet-ui>
    </div>
</div>

<h3>Player Wallets</h3>
<div class="players row" *ngIf="wallets.players$ | async as pl">
    <div class="player" *ngFor="let p of pl; let i=index;">
        <app-wallet-ui icon="accessibility" [player]="p"></app-wallet-ui>
    </div>
</div>


<h1><mat-icon>note_alt</mat-icon>START MINING</h1>

<h1><mat-icon>note_alt</mat-icon>REWARDS</h1>

<h1><mat-icon>note_alt</mat-icon>LIQUIFY</h1>


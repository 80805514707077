import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConnectComponent } from './views/connect/connect.component';
import { MainComponent } from './views/main/main.component';
import { SplashComponent } from './views/splash/splash.component';
import { PlaygroundComponent } from './views/playground/playground.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { WalletUIComponent } from './views/playground/wallet-ui/wallet-ui.component'

@NgModule({
  declarations: [
    AppComponent,
    ConnectComponent,
    MainComponent,
    SplashComponent,
    PlaygroundComponent,
    WalletUIComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,

    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { WalletsService } from 'src/app/services/wallets.service';

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent implements OnInit {

  constructor(public wallets: WalletsService) { }

  ngOnInit(): void {
  }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConnectComponent } from './views/connect/connect.component';
import { MainComponent } from './views/main/main.component';
import { PlaygroundComponent } from './views/playground/playground.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'connect', component: ConnectComponent },
  { path: 'playground', component: PlaygroundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
